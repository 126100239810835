/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';

import '../../assets/sass/global.scss';

function Layout({ children }) {
  return (
    <>
      <main className="container">{children}</main>
      <footer>
        © copyright
        {new Date().getFullYear()}
        .
        <img src="/logo.svg" alt="logo" />
        <span>Rakotomalala Ny Fenitra</span>
      </footer>
    </>
  );
}

export default Layout;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
